<template>
  <div>
    <div class="slider desktop">
      <VueSlickCarousel
        :autoplay="true"
        :infinite="true"
        :speed="1000"
        :autoplaySpeed="6000"
        :slidesToShow="4"
        :slidesToScroll="4"
      >
        <div>
          <b-img class="sponImg" :src="spons[6].acf.image"></b-img>
          
        </div>
        <div>
          <b-img class="sponImg aus" :src="spons[1].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[2].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[3].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[4].acf.image"></b-img>
        </div>
        <!-- <div>
          <b-img class="sponImg" :src="spons[5].acf.image"></b-img>
        </div> -->
        <div>
          <b-img class="sponImg alps" :src="spons[0].acf.image"></b-img>
        </div>
      </VueSlickCarousel>
    </div>
        <div class="slider mob">
      <VueSlickCarousel
        :autoplay="true"
        :infinite="true"
        :speed="1000"
        :autoplaySpeed="6000"
        :slidesToShow="1.5"
      >
        <div>
          <b-img class="sponImg" :src="spons[0].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[1].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[2].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[3].acf.image"></b-img>
        </div>
        <div>
          <b-img class="sponImg" :src="spons[4].acf.image"></b-img>
        </div>
        <!-- <div>
          <b-img class="sponImg" :src="spons[5].acf.image"></b-img>
        </div> -->
        <div>
          <b-img class="sponImg alps" :src="spons[6].acf.image"></b-img>
        </div>
      </VueSlickCarousel>
    </div>
    <div>
      <div class="lightB">
        <b-container>
          <template v-if="isMobile(true)">
            <b-navbar toggleable="lg" type="dark" variant="info" class="container footerSecMob" style="text-align: center">
                <b-row>
                    <b-col sm='6'>
                        <b-navbar-brand href="https://ocs-software.com/">
                            <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/OCS_Software_Logo_2017_WO.png"></b-img>
                        </b-navbar-brand>
                    </b-col>
                    <b-col sm='6'>
                        <div class="wereConnected">
                          <h5 class="connected">WE’RE CONNECTED</h5>
                          <p class="FooterText">OCS Sport is part of OCS Software, offering well <br>established digital skills to businesses who want leading edge technology solutions.</p>
                        </div>
                        <b-navbar-nav class="ml-auto">
                          <b-nav-item class="navItems foot" href="#about" right>@ OCS Sport is a Trading name of iOffice Ltd</b-nav-item>
                          <b-nav-item class="navItems foot" href="tel:+442071834397" right>+ 44 (0) 207 183 4397</b-nav-item>
                        </b-navbar-nav>
                    </b-col>
                </b-row>
            </b-navbar>
          </template>
          <template v-else> 
            <b-navbar toggleable="lg" type="dark" variant="info" class="container">
                <b-navbar-brand href="https://ocs-software.com/">
                    <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/OCS_Software_Logo_2017_WO.png"></b-img>
                </b-navbar-brand>
                <div class="wereConnected">
                    <h5 class="connected">WE’RE CONNECTED</h5>
                    <p class="FooterText">OCS Sport is part of OCS Software, offering well <br>established digital skills to businesses who want leading edge technology solutions.</p>
                </div>
                <b-navbar-nav class="ml-auto tradingLine">
                    <b-nav-item class="navItems foot" href="#about" right>
                      <div class="SupportLogoBlock">
                        <div>
                          <a href="https://ocs-digital.com/" target="_blank">
                            <img class="supportLogoSplash" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCS-Digital_white.png">
                          </a>
                        </div>
                        <div>
                          <a href="https://simpark.club/"  target="_blank">
                            <img style="width: 60px; margin-left:20px" class="supportLogoSplash" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/11/SimPark_White_Sqaure.png">
                          </a>
                        </div>
                      </div>
                      <br>
                      @ OCS Sport is a Trading name of iOffice Ltd
                      <a class="navItems foot" href="tel:+442071834397" right style="color:#fff; padding-left:10px">+ 44 (0) 207 183 4397</a>
                    </b-nav-item>
                </b-navbar-nav>
            </b-navbar>
          </template>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "footer",
  components: { VueSlickCarousel },
  data() {
    return {
      spons: [],
    };
  },
  methods: {
      isMobile() {
          if( screen.width <= 500 ) {
              return true;
          }
          else {
              return false;
          }
      },
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_API_URL + "/wp-json/wp/v2/sponsors?per_page=20") // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.spons = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
img.sponImg.alps {
  margin-top: 88px;
}
img.sponImg.aus {
  margin-top: 50px;
}
.SupportLogoBlock {
    display: inline-flex;
    /* margin-left: 160px; */
}
.supportLogoSplash {
    width: 50px;
}
.tradingLine {
    margin-top: -5px;
}
.bg-info {
    background-color: #003460 !important;
}
.logo {
    width: 100px;
    /* margin-top: -20px; */
    margin-top: 5px;
}
.wereConnected {
    padding-left: 30px;
    padding-left: 30px;
    padding-top: 15px;
}
h5.connected {
    font-size: 16px;
    text-align: left;
}
p.FooterText {
    text-align: left;
    font-size: 12px;
}
.navItems {
    font-size: 12px;
}
::v-deep .slick-slider > button {
  display: none !important;
}
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.sponImg {
  width: 300px;
}
.slider {
  padding: 2rem 0rem;
  background-color: #f4f4f6;
}
.lightB {
  padding: 1.5rem 1rem 1.5rem 1rem;
  background-color: #003460;
  color: #ffffff;
}
.lightB > p > a {
  color: #fff;
}
.copyRight{
  color:#fff
}
.number>a{
  color:#fff;
  float: right;
}
.mob{
  display: none;
}
@media only screen and (max-width: 900px) {
  .desktop{
    display: none;
  }
  .mob{
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  h5.connected[data-v-6c4d8baa] {
    font-size: 16px;
    text-align: center;
  }
  .wereConnected {
    padding-left: 0px!important;
    padding-top: 0px;
    text-align: center;
  }
  p.FooterText {
    text-align: center;
    font-size: 12px;
  }
}
</style>
